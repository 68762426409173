.root {
  color: var(--black2);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 9px 18px;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  white-space: nowrap;
  
  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.link {
  text-decoration: none;
  color: inherit;
  &:hover, &:active {
    color: inherit;
  }
}

.base {
  &.dark {
    background-color: var(--blue2);
  }
  &.light {
    background-color: var(--blue5);
    color: var(--white);
  }
  &:hover:not(.disabled) {
    background-color: var(--blue1);
  }
}

.white {
  background-color: var(--white1);
  &:hover:not(.disabled) {
    background-color: var(--grey9);
  }
  &.light {
    background-color: var(--white);
    &:hover:not(.disabled) {
      background-color: var(--grey9);
    }
  }
}

.grey {
  background-color: var(--grey28);
  // &:hover:not(.disabled) {
  //   background-color: var(--grey9);
  // }
}

.inverse {
  background-color: transparent;
  color: var(--blue5);
  border: 1px solid var(--blue7);
  &:hover:not(.disabled) {
    background-color: var(--blue5);
    color: var(--white);
  }
}

.inverse-blue {
  background-color: var(--blue10);
  color: var(--blue5);
  &:hover:not(.disabled) {
    background-color: var(--blue5);
    color: var(--white);
  }
}

.black {
  color: var(--white);
  background-color: var(--black25);
  border: 1px solid var(--black5);
  &:hover:not(.disabled) {
    background-color: var(--black26);
  }
}

.black-light {
  color: var(--white);
  background-color: var(--black16);
  &:hover:not(.disabled) {
    background-color: var(--black);
  }
}

.black-opacity {
  color: var(--white);
  background-color: var(--black9);
  &:hover:not(.disabled) {
    background-color: var(--black3);
  }
}

.orange {
  color: var(--white);
  background-color: var(--orange1);
  border: 1px solid transparent;
  &:hover:not(.disabled) {
    background-color: var(--orange1);
  }
}

.transparent {
  color: var(--white);
  background-color: transparent;
  &:hover:not(.disabled) {
    background-color: var(--black3);
  }
  &.active {
    color: var(--blue1);
  }
}

.dashed {
  color: var(--white);
  background-color: transparent;
  border: 1px dashed var(--black4);
  &:hover:not(.disabled) {
    background-color: var(--black5);
  }
  &.error {
    background-color: var(--red1);
  }
}

.base-link {
  background-color: transparent;
  padding: 0;
  &.dark {
    color: var(--white);
  }
  &.light {
    color: var(--black);
  }
  &:hover:not(.disabled), &.active {
    color: var(--blue1);
  }
}

.wide {
  padding: 10px 90px;
}

.fullWidth {
  width: 100%;
}

.loading-center {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.loading {
  margin-right: 9px;
}
